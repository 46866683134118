import { UilAngleRight } from '@iconscout/react-unicons';
import React, { useCallback, useMemo, useState } from 'react';

import MySlotListCard from '@/components/Molecule/Show/MySlotListCard';
import SlotCard from '@/components/Molecule/Show/SlotCard';
import SlotListWrapper from '@/components/Molecule/Show/SlotListWrapper';
import ShowsTabs from '@/components/Molecule/ShowsTabs';
import { Button } from '@/components/ui/button';
import { MySlotFragment } from '@/generated/graphql';
import { InternalLink, RouterPathType } from '@/hooks/useInternalRouter';
import Paginated from '@/models/Paginated';
import { SlotType } from '@/models/Slot';

import MaxMdOnly from 'src/components/Layout/MaxMdOnly';
import MdOnly from 'src/components/Layout/MdOnly';

function HomeFutureShowsSection(props: {
  recommendedShows: Paginated<MySlotFragment>;
  upcomingShows: Paginated<MySlotFragment>;
  visibleCount: number;
}) {
  const { recommendedShows, upcomingShows, visibleCount } = props;

  const [currentTab, setCurrentTab] = useState<
    SlotType.Recommended | SlotType.Upcoming
  >(
    recommendedShows.list.length > 0 ? SlotType.Recommended : SlotType.Upcoming,
  );

  const shows = useMemo<Paginated<MySlotFragment>>(() => {
    switch (currentTab) {
      case SlotType.Upcoming: {
        return upcomingShows;
      }
      case SlotType.Recommended: {
        return recommendedShows;
      }
      default: {
        throw new Error(`Invalid tab: ${currentTab}`);
      }
    }
  }, [currentTab, recommendedShows, upcomingShows]);

  const href = useMemo<RouterPathType>(() => {
    switch (currentTab) {
      case SlotType.Recommended: {
        return `/slots/recommended`;
      }
      case SlotType.Upcoming: {
        return `/slots/upcoming`;
      }
      default: {
        throw new Error(`Invalid tab: ${currentTab}`);
      }
    }
  }, [currentTab]);

  const handleTabChange = useCallback(
    (tab: SlotType.Upcoming | SlotType.Recommended) => {
      setCurrentTab(tab);
    },
    [],
  );

  return (
    <>
      <MaxMdOnly>
        <MySlotListCard
          slots={recommendedShows.list}
          slotType={SlotType.Recommended}
          title="Recommended Shows"
          href="/slots/recommended"
          messageIfEmpty={`No recommended shows,\n check back soon`}
        />
        <MySlotListCard
          slots={upcomingShows.list}
          slotType={SlotType.Upcoming}
          title="Upcoming Shows"
          href="/slots/upcoming"
          messageIfEmpty={`No upcoming shows,\n check back soon`}
        />
      </MaxMdOnly>
      <MdOnly>
        <div className="flex w-full flex-col gap-40">
          <div className="header-2-b flex items-center justify-between text-gray-25">
            <ShowsTabs
              tab={currentTab}
              onTabChange={handleTabChange}
              className="header-2-b"
            />
            {shows.list.length > visibleCount && (
              <InternalLink href={href}>
                <Button
                  size="lg"
                  variant="secondary"
                  className="h-44 pl-24 pr-14"
                >
                  <span>See All</span>
                  <UilAngleRight className="ml-4 size-24 fill-gray-25" />
                </Button>
              </InternalLink>
            )}
          </div>
          <SlotListWrapper
            className="flex gap-24"
            messageIfEmpty={`No ${currentTab.toLowerCase()} shows,\n check back soon`}
            empty={shows.list.length === 0}
          >
            {shows.list.slice(0, visibleCount).map((slot) => (
              <SlotCard
                slot={slot}
                type={currentTab}
                key={slot.uuid}
                variant="grid"
              />
            ))}
          </SlotListWrapper>
        </div>
      </MdOnly>
    </>
  );
}

export default HomeFutureShowsSection;
