import { UilAngleRight } from '@iconscout/react-unicons';
import React from 'react';

import MySlotListCard from '@/components/Molecule/Show/MySlotListCard';
import SlotCard from '@/components/Molecule/Show/SlotCard';
import SlotListWrapper from '@/components/Molecule/Show/SlotListWrapper';
import { Button } from '@/components/ui/button';
import { MySlotFragment } from '@/generated/graphql';
import { InternalLink } from '@/hooks/useInternalRouter';
import Paginated from '@/models/Paginated';
import { SlotType } from '@/models/Slot';

import MaxMdOnly from 'src/components/Layout/MaxMdOnly';
import MdOnly from 'src/components/Layout/MdOnly';

function HomePastShowSection(props: {
  shows: Paginated<MySlotFragment>;
  visibleCount: number;
}) {
  const { shows, visibleCount } = props;

  return (
    <>
      <MaxMdOnly>
        <MySlotListCard
          slots={shows.list}
          slotType={SlotType.Past}
          title="Shows History"
          href="/history"
          messageIfEmpty={`No show history,\n check back soon`}
        />
      </MaxMdOnly>
      <MdOnly>
        <div className="flex w-full flex-col gap-40">
          <div className="flex items-center justify-between">
            <span className="header-2-b text-gray-25">Show History</span>
            {shows.list.length > visibleCount && (
              <InternalLink href="/history">
                <Button
                  size="lg"
                  variant="secondary"
                  className="h-44 pl-24 pr-14"
                >
                  <span>See All</span>
                  <UilAngleRight className="ml-4 size-24 fill-gray-25" />
                </Button>
              </InternalLink>
            )}
          </div>
          <SlotListWrapper
            className="flex flex-wrap gap-24"
            messageIfEmpty={`No show history,\n check back soon`}
            empty={shows.list.length === 0}
          >
            {shows.list.slice(0, visibleCount).map((slot) => (
              <SlotCard
                slot={slot}
                type={SlotType.Past}
                key={slot.uuid}
                variant="grid"
              />
            ))}
          </SlotListWrapper>
        </div>
      </MdOnly>
    </>
  );
}

export default HomePastShowSection;
